<template>
  <div class="assets-life list-page">
    <div class="search-box">
      <div class="title">
        <h1>搜索</h1>
        <p>Search Find</p>
      </div>
      <el-form :model="formData" ref="formRef" class="body el-row">
        <el-col :span="8">
          <el-form-item label="关键字" prop="keyword">
            <el-input v-model="formData.keyword" placeholder="输入工具编号/工具名称/工位号进行搜索"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="是否需要更换" prop="needChange">
            <el-select v-model="formData.needChange" placeholder="请选择">
              <el-option label="是" :value="true"></el-option>
              <el-option label="否" :value="false"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8" class="search-btn-box">
          <el-button class="reset" @click="handleReset('formRef')">重置</el-button>
          <el-button class="search active" @click="handleSearch()">搜索</el-button>
        </el-col>
      </el-form>
    </div>
    <div class="list-box">
      <div class="global-operate-block">
        <div class="title">
          <h1>查询结果</h1>
          <p>search result</p>
        </div>
        <div class="global-operate-btn-box">
          <el-button @click="handleExport">导出预警数据</el-button>
          <el-button @click="handleAdd">新增工具</el-button>
          <el-button @click="ptsDialogVisible = true">设置PTS数据频率</el-button>
          <el-upload
            action
            class="upload-pts"
            :show-file-list="false"
            :before-upload="importPTS">
            <el-button>导入PTS数据</el-button>
          </el-upload>
        </div>
      </div>
      <el-table :data="tableData">
        <el-table-column prop="workStationName" label="工段"></el-table-column>
        <el-table-column prop="classGroupName" label="班组"></el-table-column>
        <el-table-column prop="empStationName" label="工位号"></el-table-column>
        <el-table-column prop="name" label="工具名称" width="170"></el-table-column>
        <el-table-column label="寿命状态" width="100">
          <!-- 绿→红（已拧紧次数usedTimes >= 建议拧紧次数warnTimes）  总：planTimes 占比：usedTimes -->
          <template #default="{row}">
            <el-progress :show-text="false" :stroke-width="10" :percentage="row.lifeSatePercentage" :color="() => lifeStateColors(row)"></el-progress>
          </template>
        </el-table-column>
        <el-table-column prop="entryDate" label="投入使用时间" width="100"></el-table-column>
        <el-table-column prop="planTimes" label="建议拧紧次数" width="80"></el-table-column>
        <!-- <el-table-column label="需使用车型" header-align="center" v-if="secondLevelMax.length > 0">
          <template #default> 
            <template v-for="(item, index) in secondLevelMax" :key="index">
              <el-table-column :prop="'carType_' + index" :label="'车型' + (index + 1)"></el-table-column>
              <el-table-column :prop="'needTimes_' + index" label="拧紧次数"></el-table-column>
            </template>
          </template>
        </el-table-column> -->
        <el-table-column prop="warnTimes" label="更换预警值" width="100"></el-table-column>
        <el-table-column prop="usedTimes" label="已拧紧次数" width="100"></el-table-column>
        <el-table-column prop="warnStartTime" label="预警开始时间" width="140"></el-table-column>
        <!-- 已使用次数usedTimes >= 预警次数warnTimes → 是 -->
        <el-table-column prop="usedTimes" label="是否需要更换" :formatter="formatterIsNeedReplace" width="70"></el-table-column>
        <el-table-column prop="status" label="是否报废" :formatter="formatterStatus" width="70"></el-table-column>
        <el-table-column label="操作" width="120" class-name="operate-col"> 
          <template #default="{row}">
            <el-button @click="handleEdit(row)">修改</el-button>
            <el-dropdown class="el-button">
              <span>更多</span>
              <template #dropdown>
                <el-dropdown-menu class="table-operate-col-menu">
                  <el-dropdown-item @click="handleCopy(row)">复制</el-dropdown-item>
                  <el-dropdown-item @click="handleSignChanged(row)">标记已更换</el-dropdown-item>
                  <el-dropdown-item @click="handleInvalid(row)">作废</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <!-- <el-button @click="handleCopy(row)">复制</el-button>
            <el-button @click="handleSignChanged(row)">标记已更换</el-button>
            <el-button @click="handleInvalid(row)" class="del">作废</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
          @current-change="handleCurrentPage"
          :current-page="pagination.currentPage"
          :page-size="pagination.pageSize"
          layout="prev, pager, next"
          :total="pagination.total">
        </el-pagination>
      </div>
    </div>

    <!-- 修改弹框 -->
    <el-dialog v-model="editDialogVisible" width="960px" :show-close="false" @close="cancelEdit('editFormRef')">
      <template #title>
        <h1>{{curEditLabel.zh}}</h1>
        <p>{{curEditLabel.en}}</p>
        <el-button class="add-capacity" icon="el-icon-plus" @click="addEditTableRow">新增产能</el-button>
      </template>
      <el-form :model="editFormData" ref="editFormRef" class="el-row edit-form" :rules="editFormRules">
        <el-col :span="8">
          <el-form-item label="工具名称" prop="name">
            <el-input v-model="editFormData.name" placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="工段" prop="workStation">
            <el-select v-model="editFormData.workStation" placeholder="请选择">
              <el-option
                v-for="item in workStationList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="班组" prop="classGroup">
            <el-select v-model="editFormData.classGroup" placeholder="请选择">
              <el-option
                v-for="item in classGroupList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="工位号" prop="empStation">
            <el-select v-model="editFormData.empStation" filterable placeholder="请选择">
              <el-option
                v-for="item in empStationList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="投入使用时间" prop="entryDate">
            <el-date-picker v-model="editFormData.entryDate" type="date" format="YYYY-MM-DD" value-format="YYYY-MM-DD" placeholder="请选择盘点时间"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="建议拧紧次数" prop="planTimes">
            <el-input v-model="editFormData.planTimes" placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="预警值" prop="warnTimes">
            <el-input v-model="editFormData.warnTimes" placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
      </el-form>
      <el-table :data="editTableData">
        <el-table-column label="序号" type="index" :index="index => index + 1"></el-table-column>
        <el-table-column label="产能编码">
          <template #default="{row, $index}">
            <el-input v-if="curEditTableRowIdx === $index" v-model="row.carType"></el-input>
            <span v-else>{{row.carType}}</span>
          </template>
        </el-table-column>
        <el-table-column label="拧紧次数">
          <template #default="{row, $index}">
            <el-input v-if="curEditTableRowIdx === $index" v-model="row.needTimes"></el-input>
            <span v-else>{{row.needTimes}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="{row, $index}">
            <el-button v-if="curEditTableRowIdx !== $index" @click="curEditTableRowIdx = $index">修改</el-button>
            <el-button v-if="curEditTableRowIdx === $index" @click="confirmEditTableRow(row)">确定</el-button>
            <el-button @click="delEditTableRow($index)" class="del">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
        <el-button @click="cancelEdit('editFormRef')">取 消</el-button>
        <el-button class="active" @click="confirmEdit('editFormRef')">保 存</el-button>
      </template>
    </el-dialog>

    <!-- 设置PTS弹框 -->
    <el-dialog v-model="ptsDialogVisible" width="600px" @close="cancelPTS('ptsFormRef')">
      <template #title>
        <h1>设置PTS数据频率</h1>
        <p>Set PTS data frequency</p>
      </template>
      <el-form :model="ptsFormData" ref="ptsFormRef" :rules="ptsFormRules" class="pst-form">
        <el-form-item label="频率值" prop="value">
          <el-input v-model="ptsFormData.value" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="时间单位" prop="type">
          <el-select v-model="ptsFormData.type" placeholder="请选择">
            <el-option
              v-for="item in ptsTimeTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="cancelPTS('ptsFormRef')">取 消</el-button>
        <el-button class="active" @click="confirmPTS('ptsFormRef')">确 定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { assets,common } from '@/api'

export default {
  name: 'AssetsLife',
  data() {
    const validateInteger = (rule, value, callback) => {
      const valid = /^(?:[1-9]\d*|0)$/.test(value)
      if (value == 0 || !valid) {
        callback(new Error('请输入正整数'))
      } else {
        callback()
      }
    }

    return {
      formData: { // 查询表单
        keyword: '',
        needChange: '',
      },
      tableData: [], // 表格数据
      pagination: { // 分页信息
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      statusList: [ // 是否报废
        {
          label: '否',
          value: 'OK'
        },
        {
          label: '是',
          value: 'SCRAP'
        }
      ],
      secondLevelMax: [], // 第二层表头

      curEditLabel: {
        zh: '',
        en: '',
      },
      editDialogVisible: false, // 修改弹框visible
      initEditFormData: { 
        name: '',
        workStation: '',
        classGroup: '',
        empStation: '',
        entryDate: '',
        planTimes: '',
        warnTimes: '',
      },
      editFormData: {...this.initEditFormData}, // 修改表单
      editFormRules: { // 修改表单的校验
        name: { required: true, message: '请输入', trigger: 'blur' },
        workStation: { required: true, message: '请选择', trigger: 'change' },
        classGroup: { required: true, message: '请选择', trigger: 'change' },
        empStation: { required: true, message: '请选择', trigger: 'change' },
        entryDate: { required: true, message: '请选择', trigger: 'change' },
        planTimes: [
          { required: true, message: '请输入', trigger: 'blur' },
          { validator: validateInteger, trigger: 'blur' }
        ],
        warnTimes: [
          { required: true, message: '请输入', trigger: 'blur' },
          { validator: validateInteger, trigger: 'blur' }
        ]
      },
      editTableData: [], // 修改表单中的工具列表
      workStationList: [], // 工段列表
      classGroupList: [], // 班组列表
      empStationList: [], // 工位号工位列表
      curEditTableRowIdx: -1, // 工具列表的当前编辑行的索引

      ptsDialogVisible: false, // pts频率类型对话框visible
      ptsTimeTypeList: [ // pts频率类型
        {
          label: '天',
          value: 'DAY'
        },
        {
          label: '小时',
          value: 'HOUR'
        },
        {
          label: '分钟',
          value: 'MINUTE'
        }
      ],
      ptsFormData: { // pst表单
        value: '',
        type: ''
      },
      ptsFormRules: { // pst表单的校验
        value: [
          { required: true, message: '请输入', trigger: 'blur' },
          { validator: validateInteger, trigger: 'blur' }
        ],
        type: { required: true, message: '请选择', trigger: 'change' },
      }
    }
  },
  created() {
    this.loadTableData()
  },
  methods: {
    // 寿命状态颜色设置
    lifeStateColors(row) {
      if(row.usedTimes < row.warnTimes) {
        return '#50FF40'
      } else {
        return '#FF4040'
      }
    },
    // 获取字典数据
    async getDict() {
      let types = 'WORK_STATION,CLASS_GROUP,EMP_STATION'
      const {code, msg, data = {}} = await common.queryDict({types})
      if (code === 0) {
        this.workStationList = data.WORK_STATION || []
        this.classGroupList = data.CLASS_GROUP || []
        this.empStationList = data.EMP_STATION || []
      } else {
        this.$message.error(msg)
      }
    },
    // 格式化资产状态
    formatterStatus(row, column, cellValue) {
      let label = cellValue
      for(let item of this.statusList) {
        if(item.value == cellValue){
          label = item.label
          continue
        }
      }
      return label
    },
    // 格式化“是否需要更换”
    formatterIsNeedReplace(row, column, cellValue) {
      return cellValue < row.warnTimes ? '否' : '是'
    },
    // 获取列表数据
    async loadTableData() {
      let params = {
        ...this.formData,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize
      }
      const {code, msg, data = [], total = 0} = await assets.getAssetsLife(params)
      if (code === 0) {
        // 设置最大行
        let maxL = 0
        let maxLItems = []
        data.forEach(row => {
          if(row.items && row.items.length  > maxL) {
            maxL = row.items.length
            maxLItems = row.items
          }
        })
        this.secondLevelMax = maxLItems

        // 拼凑表格数据
        let td = data.map(row => {
          let {items = [], ...others} = row
          let maxObj = {} 
          if(items && items.length > 0) {
            for(let i = 0; i <= maxL; i++) {
              maxObj['carType_' + i] = items[i] && items[i].carType
              maxObj['needTimes_' + i] = items[i] && items[i].needTimes
            }
          } else {
            items = []
          }
          // 寿命状态百分比值
          let lifeSatePercentage = Math.ceil(others.usedTimes / others.planTimes * 100 )

          return {
            ...others,
            ...maxObj,
            items,
            lifeSatePercentage
          }
        })

        this.tableData = td
        this.pagination.total = total
      } else {
        this.$message.error(msg)
      }
    },
    // 改变当前页
    handleCurrentPage(currentPageNo) {
      this.pagination.currentPage = currentPageNo
      this.loadTableData()
    },
    // 搜索
    handleSearch() {
      this.pagination.currentPage = 1
      this.loadTableData()
    },
    // 重置
    handleReset(formName) {
      this.$refs[formName].resetFields()
      this.pagination.currentPage = 1
      this.loadTableData()
    },
    // 点击新增
    handleAdd() {
      this.getDict()
      this.editFormData = this.initEditFormData
      this.editTableData = [{
        carType: '',
        needTimes: ''
      }]
      this.curEditTableRowIdx = 0

      this.curEditLabel.zh = '新增'
      this.curEditLabel.en = 'Add'
      this.editDialogVisible = true
    },
    // 点击修改
    handleEdit(row) {
      this.getDict()
      this.editFormData = {...row}
      this.editTableData = [...row.items]
      this.curEditTableRowIdx = -1

      this.curEditLabel.zh = '修改'
      this.curEditLabel.en = 'Edit'
      this.editDialogVisible = true
    },
    // 确认修改
    confirmEdit(formName) {
      this.$refs[formName] && this.$refs[formName].validate && this.$refs[formName].validate(async (valid) => {
        if(valid) {
          if(this.curEditTableRowIdx == -1) {
            let params = {
              ...this.editFormData,
              items: this.editTableData
            }
            const {code, msg} = await assets.saveTool(params)
            if(code === 0) {
              this.editDialogVisible = false
              this.$message.success(`${this.curEditLabel.zh}成功`)
              this.loadTableData()
            } else {
              this.$message.error(msg)
            }
          } else {
            this.$message.warning('请完成填写或删除当前行')
          }
        }
      })
    },
    // 取消修改
    cancelEdit(formName) {
      this.$refs[formName].resetFields()
      this.editDialogVisible = false
    },
    // 新增工具列表行
    addEditTableRow() {
      if(this.curEditTableRowIdx == -1) {
        this.editTableData.push({
          carType: '',
          needTimes: ''
        })
        this.curEditTableRowIdx = this.editTableData.length - 1
      } else {
        this.$message.warning('请完成填写或删除当前行')
      }
    },
    // 删除工具列表行
    delEditTableRow(idx) {
      this.editTableData.splice(idx, 1)
      this.curEditTableRowIdx = -1
    },
    // 确认修改工具列表行
    confirmEditTableRow(row) {
      if(row.carType.trim() && row.needTimes.toString().trim()) {
        const valid = /^(?:[1-9]\d*|0)$/.test(row.needTimes)
        if(row.needTimes == 0 || !valid){
          this.$message.warning('拧紧次数需正整数')
          return
        }
        this.curEditTableRowIdx = -1
      } else {
        this.$message.warning('请完成填写或删除当前行')
      }
    },
    // 点击复制
    handleCopy(row) {
      this.getDict()
      let {id, ...others} = row
      this.editFormData = {
        ...others,
        copyId: id
      }
      this.editTableData = [...others.items]
      this.curEditTableRowIdx = -1

      this.curEditLabel.zh = '复制'
      this.curEditLabel.en = 'Copy'
      this.editDialogVisible = true
    },
    // 标记已更换
    async handleSignChanged({id}) {
       this.$confirm('确认进行此操作吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const {code, msg} = await assets.signChanged({id})
        if(code === 0) {
          this.$message.success('操作成功')
          this.loadTableData()
        } else {
          this.$message.error(msg)
        }
      }).catch(() => {
        console.info('取消标记已更换操作')
      })
    },
    // 作废
    async handleInvalid({id}) {
      this.$confirm('确认进行此操作吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const {code, msg} = await assets.signInvalid({id})
        if(code === 0) {
          this.$message.success('操作成功')
          this.loadTableData()
        } else {
          this.$message.error(msg)
        }
      }).catch(() => {
        console.info('取消作废操作')
      })
    },
    // 确定设置PTS数据频率
    confirmPTS(formName) {
      this.$refs[formName] && this.$refs[formName].validate && this.$refs[formName].validate(async (valid) => {
        if(valid) {
          const {code, msg} = await assets.setPTSFrequency(this.ptsFormData)
          if(code === 0) {
            this.ptsDialogVisible = false
            this.$message.success(`设置PTS数据频率成功`)
          } else {
            this.$message.error(msg)
          }
        }
      })
    },
    // 取消设置PTS数据频率
    cancelPTS(formName) {
      this.$refs[formName].resetFields()
      this.ptsDialogVisible = false
    },
    // 导入PTS数据
    async importPTS(file) {
      let fd = new FormData()
      fd.set("file", file)
      let {code, msg} = await assets.uploadPTS(fd)
      if (code === 0) {
        this.$message.success('导入成功')
      } else {
        this.$message.error(msg)
      }
      return false
    },
    // 导出预警数据
    async handleExport() {
      await common.downloadFile('/pts/export/warning')
    },
  }
}
</script>

<style lang="scss" scoped>
.assets-life {
  .upload-pts {
    display: inline-block;
    margin-left: 20px;
  }
  .list-box {
    .el-table {
      background:#20202f;
      ::v-deep tr {
        background:#20202f;
      }
      &.el-table--border::after, &.el-table--group::after {
        content: none;
      }
    }
  }

  ::v-deep .el-dialog {
    .add-capacity {
      position: absolute;
      right: 32px;
      top: 32px;
    }
    .el-row {
      & > .el-col:nth-of-type(3n+1) {
        .el-form-item {
          .el-form-item__label {
            width: 84px;
          }
        }
      }
    }
    .el-form {
      .el-form-item {
        .el-form-item__label {
          width: 120px;
        }
        .el-form-item__content {
          .el-date-editor.el-input {
            width: 100%;
          }
          .el-select {
            width: 100%;
          }
        }
      }
    }
    .el-table {
      .el-table__body {
        .el-input {
          width: 70%;
        }
        tr:hover>td {
          background: transparent !important;
        }
      } 
    }
  }
}
</style>